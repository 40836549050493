import styled from 'styled-components';
import Link from 'next/link';
import {withRouter} from 'next/router';
import HamburgerMenu from 'react-hamburger-menu';
import Grid from 'styled-components-grid';
import theme from '../lib/theme';
import {useState} from 'react';

const SectionWrapper = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: ${({isOpen}) => (isOpen ? `0` : null)};
  left: 0;
  background-color: ${({theme}) => theme.colors.white};
  z-index: 1;
`;

const NavigatioHeaderWrapper = styled.div`
  height: ${({theme}) => theme.menuHeight};
  box-shadow: ${({isOpen}) => (isOpen ? null : `rgba(0, 0, 0, 0.12) 0px 0px 30px 0px`)};
`;

const NavigationHeader = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const NavigationMenu = styled.div`
  display: ${({isOpen}) => (isOpen ? `flex` : `none`)};
  overflow-y: scroll;
  flex: 1;
`;

const NavigationLogo = styled.img`
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
  height: 100%;
`;

const NavigationSpacer = styled.div`
  flex: 1;
`;

const HamburgerWrapper = styled.div`
  justify-content: center;
  flex-direction: column;
  display: flex;
  cursor: pointer;
`;

const MenuItemWrapper = styled.a`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  text-decoration: unset;
  color: unset;
  &:hover {
    text-decoration: unset;
    color: unset;
    > div:first-child {
      color: ${({theme}) => theme.colors.green};
    }
  }
`;

const StyledGrid = styled(Grid)`
  overflow: auto;
`;

const StyledUnit = styled(Grid.Unit)`
  padding: 1em;
`;

const MenuItemTitle = styled.div`
  display: flex;
`;

const MenuItemSub = styled.div`
  color: ${({theme}) => theme.colors.gray};
  padding-top: 0.5em;
  display: flex;
  font-size: 0.75em;
`;

const MenuItem = ({title, sub, link, as, action, toggleMenu = () => {}}) => {
  if (link) {
    return (
      <Link href={link} as={as} passHref>
        <MenuItemWrapper onClick={() => toggleMenu()}>
          <MenuItemTitle>{title}</MenuItemTitle>
          <MenuItemSub>{sub}</MenuItemSub>
        </MenuItemWrapper>
      </Link>
    );
  } else if (action) {
    return (
      <MenuItemWrapper
        onClick={() => {
          action();
          toggleMenu();
        }}>
        <MenuItemTitle>{title}</MenuItemTitle>
        <MenuItemSub>{sub}</MenuItemSub>
      </MenuItemWrapper>
    );
  }
};

const Navigation = ({items, router: {pathname}}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <NavigationWrapper isOpen={isOpen}>
      <NavigatioHeaderWrapper isOpen={isOpen}>
        <SectionWrapper>
          <NavigationHeader>
            <Link href={'/'} as={'/'} passHref>
              <a>
                <NavigationLogo height='40' width='162' src={'/static/assets/logo.png'} alt='Pernikl Logo' />
              </a>
            </Link>
            <NavigationSpacer />
            {items.length > 0 && (
              <HamburgerWrapper>
                <HamburgerMenu
                  isOpen={isOpen}
                  menuClicked={() => setIsOpen(!isOpen)}
                  width={30}
                  height={20}
                  strokeWidth={2}
                  rotate={0}
                  color={theme.colors.black}
                  borderRadius={2}
                  animationDuration={0.5}
                />
              </HamburgerWrapper>
            )}
          </NavigationHeader>
        </SectionWrapper>
      </NavigatioHeaderWrapper>
      <NavigationMenu isOpen={isOpen}>
        <SectionWrapper>
          <StyledGrid>
            {items.length > 0 &&
              items.map((item, index) => (
                <StyledUnit key={index} size={{xs: 1, sm: 1, md: 1 / 2, lg: 1 / 3}}>
                  <MenuItem
                    {...item}
                    toggleMenu={() => {
                      setIsOpen(!isOpen);
                      window.scrollTo(0, 0);
                    }}
                  />
                </StyledUnit>
              ))}
          </StyledGrid>
        </SectionWrapper>
      </NavigationMenu>
    </NavigationWrapper>
  );
};

export default withRouter(Navigation);
