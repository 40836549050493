import 'intersection-observer';
import React, {Fragment, useEffect} from 'react';
import theme, {GlobalStyle} from '../lib/theme';
import {ThemeProvider} from 'styled-components';
import Menu from '../components/menu';
import {useRouter} from 'next/router';
import * as gtag from '../lib/gtag';
import {useAckeeAnalytics} from '../hooks/useAckeeAnalytics';

const App = ({Component, pageProps}) => {
  const router = useRouter();
  useAckeeAnalytics();
  useEffect(() => {
    router.events.on('routeChangeComplete', (url) => {
      gtag.pageview(url);
    });
  }, []);
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyle />
          <Menu
            items={[
              {
                title: 'Pernikl',
                sub: 'Startseite',
                as: '/',
                link: '/',
              },
              {
                title: 'Aktuelles',
                sub: 'Neuigkeiten',
                as: '/news',
                link: '/news',
              },
              {
                title: 'Das Team',
                sub: 'Ihre Ansprechpartner',
                as: '/team',
                link: '/team',
              },
              {
                title: 'Industrieller-Schallschutz',
                sub: 'Individuelle Produkte im Bereich Schallschutz',
                as: '/produkt-kategorie/industrieller-schallschutz',
                link: '/produkt-kategorie/[slug]',
              },
              {
                title: 'Raumakustik',
                sub: 'Individuelle Produkte im Bereich Raumakustik',
                as: '/produkt-kategorie/raumakustik',
                link: '/produkt-kategorie/[slug]',
              },
              {
                title: 'Lärmminderung',
                sub: 'Individuelle Produkte im Bereich Lärmminderung',
                as: '/produkt-kategorie/laermminderung',
                link: '/produkt-kategorie/[slug]',
              },
              {
                title: 'Referenzen',
                sub: 'Gemeinsame Lösungen - Kunden und Anwendungsbereiche',
                as: '/referenzen',
                link: '/referenzen',
              },
              {
                title: 'Kontakt',
                sub: 'Ihr Kontakt zu uns',
                as: '/kontakt',
                link: '/kontakt',
              },
              {
                title: 'Impressum',
                sub: 'Informationen zum Impressum',
                as: '/impressum',
                link: '/impressum',
              },
              {
                title: 'Datenschutz',
                sub: 'Informationen zum Datenschutz',
                as: '/datenschutz',
                link: '/datenschutz',
              },
            ]}
          />
          <div key={router.route}>
            <Component {...pageProps} />
          </div>
        </Fragment>
      </ThemeProvider>
    </Fragment>
  );
};

export default App;

export const reportWebVitals = (metric) => {
  gtag.event({
    category: `Next.js ${metric?.label} metric`,
    action: metric?.name,
    value: Math.round(metric?.name === 'CLS' ? metric?.value * 1000 : metric?.value),
    label: metric?.id,
  });
};
