import {useRouter} from 'next/router';
import {useEffect} from 'react';
import * as ackeeTracker from 'ackee-tracker';

export const useAckeeAnalytics = () => {
  const router = useRouter();

  const pageview = () => {
    if (typeof window !== 'undefined') {
      ackeeTracker
        .create(
          {
            server: 'https://ackee.afink.at',
            domainId: '0d8ba1b6-7c91-43d6-a467-ae1c2b5a82ac',
          },
          {
            detailed: true,
            ignoreLocalhost: true,
          }
        )
        .record();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      pageview();
      router.events.on('routeChangeComplete', pageview);
    }
  }, []);
};
