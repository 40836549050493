export const isProd = process.env.NODE_ENV === 'production';

export default {
  MAIL_SERVER_HOST: 'srv285.adino.at',
  MAIL_SERVER_PORT: '465',
  MAIL_SERVER_SECURE: 'true',
  MAIL_SERVER_USER: 'no_reply@pernikl.com',
  MAIL_SERVER_PASSWORD: 'PVR4*xyw11',
  MAIL_FROM: 'no_reply@pernikl.com',
  MAIL_BCC: 'office@pernikl.com',
  GOOGLE_TRACKING_ID: isProd ? 'UA-69832132-1' : '',
};
